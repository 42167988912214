import { createRouter, createWebHistory } from "vue-router";
// https://www.newgrounds.com/   网址111
import HomeView from "../views/home1/index.vue";
import PlayView from "../views/play1/index.vue";
const routes = [
  {
    path: "/",
    name: "shouye",
    component: HomeView,
  },
  {
    path: "/play",
    name: "play",
    component: PlayView,
  },
];
// https://www.nexusmods.com/   网址222
// import HomeView from "../views/home2/index.vue";
// import PlayView from "../views/play2/index.vue";
// const routes = [
//   {
//     path: "/",
//     name: "shouye",
//     component: HomeView,
//   },
//   {
//     path: "/play",
//     name: "play",
//     component: PlayView,
//   },
// ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
