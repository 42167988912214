<template>
  <div class="skin-general-desktop">
    <div class="page-dimmer"></div>
    <div id="activetask-saving-msg" style="display: none">
      <em class="fa fa-spin fa-spinner"></em> Saving
    </div>
    <div id="passport_container" class="passport-container"></div>

    <div
      class="blackout-hover"
      id="blackout_hover"
      style="display: none; overflow: hidden"
    >
      <div class="blackout-inner">
        <div class="blackout-bookend"></div>
        <div class="blackout-bookshelf">
          <div class="blackout-bookend shimmed"></div>
          <div class="blackout-center" id="blackout_center"></div>
          <div class="blackout-bookend shimmed"></div>
        </div>
        <div class="blackout-bookend"></div>
      </div>
    </div>
    <div class="blackout" id="blackout">
      <div class="blackout-bg" id="blackout-bg"></div>
    </div>
    <div id="_ngHiddenAudioPlayerWrapper" class="ng-audio-player-global">
      <audio id="global-audio"></audio>

      <div class="ng-apg-centered">
        <div id="_ngHiddenAudioPlayer" class="ng-apg-bounds">
          <div class="media-player-playback-controls">
            <button
              class="media-player-previous"
              id="global-audio-player-previous"
              title="Previous Track"
              disabled
            >
              <i class="fa fa-step-backward" role="presentation"></i>
            </button>

            <button
              class="media-player-play"
              id="global-audio-player-play"
              title="Play"
              disabled
            >
              <i class="fa fa-play" role="presentation"></i>
            </button>

            <button
              class="media-player-pause"
              id="global-audio-player-pause"
              title="Pause"
              disabled
            >
              <i class="fa fa-pause" role="presentation"></i>
            </button>

            <button
              class="media-player-stop"
              id="global-audio-player-stop"
              title="Stop"
              disabled
            >
              <i class="fa fa-stop" role="presentation"></i>
            </button>

            <button
              class="media-player-next"
              id="global-audio-player-next"
              title="Next Track"
              disabled
            >
              <i class="fa fa-step-forward" role="presentation"></i>
            </button>

            <span class="spacer"></span>

            <button
              class="media-player-repeat"
              id="global-audio-player-repeat"
              title="Repeat All Tracks"
              disabled
            >
              <i class="fa fa-retweet" role="presentation"></i>
            </button>
          </div>
          <div class="media-player-progress">
            <div
              class="media-player-current-time"
              id="global-audio-player-progress"
            >
              00:00
            </div>

            <div class="media-player-progression">
              <div class="position" id="global-audio-player-container"></div>
            </div>

            <div
              class="media-player-duration"
              id="global-audio-player-duration"
            >
              00:00
            </div>
          </div>

          <div class="media-player-volume-control">
            <button id="global-audio-player-volumeToggle">
              <i class="fa fa-volume-up"></i>
            </button>

            <div class="bar off" id="global-audio-player-volume"></div>
          </div>
          <div
            id="_ngHiddenAudioPlayerDetails"
            class="media-player-display"
          ></div>
        </div>
      </div>
    </div>
    <div id="notification-shim" class="notification-shim"></div>
    <div class="notification-bar" id="notification-bar">
      <div class="wrapper">
        <div class="logo padded-left">
          <!-- <img :src="topLogoStyle2" /> -->
          <!-- <a href="/">Newgrounds</a> -->
        </div>
        <div class="search" role="search" v-if="!isMobile">
          <form
            id="topsearch-elastic"
            action="https://www.newgrounds.com/search/summary"
            method="get"
          >
            <input type="hidden" name="suitabilities" value="etm" />

            <input
              placeholder="Search Newgrounds..."
              type="text"
              name="terms"
              id="topsearch_text"
              value=""
              maxlength="40"
              aria-label="Search Newgrounds..."
            /><button type="submit" class="topsearch-submit" title="Search">
              <em class="fa fa-search"></em>
            </button>
          </form>
        </div>
        <div class="notifications padded-right" id="notification-buttons">
          <div class="notification-login-side">
            <a href="/" rel="nofollow" id="passport_login">Login</a>
          </div>
        </div>
      </div>
    </div>
    <div class="outer" id="outer-skin">
      <div class="header-nav-row" id="header-nav-row">
        <div
          class="header-nav-container header-nav-relative"
          id="header-nav-container"
        >
          <nav id="topnav" class="header-nav-buttons">
            <a
              href="/"
              v-for="(item, index) in meunList"
              :key="index"
              :class="`header-nav-button-${item.name.toLowerCase()}`"
            >
              {{ item.name }}
            </a>
          </nav>
        </div>
      </div>

      <div id="ng-global-video-player-shimwrap">
        <div id="ng-global-video-player-shim"></div>
      </div>
      <div id="ng-global-video-player-wrapper">
        <div id="ng-global-video-player"></div>
      </div>
      <div class="skin-wrapper" v-if="!isMobile">
        <div class="skin-inner">
          <div
            class="background"
            style="
              background-image: url('//img.ngfiles.com/themes/000/71e4a5c61737118029.png?1737118114');
            "
          >
            <img
              src="//img.ngfiles.com/themes/000/71e4a5c61737118029.png?1737118114"
              alt="Newgrounds Background Image Theme"
            />
          </div>
          <div class="skin-bars"></div>
        </div>
      </div>

      <div id="header-insert"></div>

      <div class="body-main">
        <div class="body-center">
          <div>
            <div class="body-guts top">
              <!-- <div class="header-deck">
                <div class="ng-latest-supporter-wide">
                  <div class="ng-latest-supporter-inner">
                    <div class="ng-latest-supporter-image">
                      <div class="user-icon-bordered">
                        <a href="/" target="_blank"
                          ><svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-large s0 c0"
                          >
                            <image
                              href="https://img.ngfiles.com/defaults/icon-user.gif?f1707337412"
                            />
                            <use href="#path_s0" /></svg
                        ></a>
                      </div>
                    </div>
                    <div class="ng-latest-supporter-meta" style="flex: 1">
                      <p class="ng-latest-supporter-title">
                        <a href="/" target="blank">Toonthug707</a>
                        just joined the crew!
                      </p>
                      <p class="ng-latest-supporter-message">
                        We need <strong>you</strong> on the team, too.
                      </p>
                      <p class="ng-latest-supporter-message">
                        Support Newgrounds and get tons of perks for just $2.99!
                      </p>
                    </div>
                    <div class="ng-latest-supporter-meta">
                      <p style="text-align: center; padding-bottom: 4px">
                        <a
                          href="/"
                          id="supporter_message_pp_open"
                          rel="nofollow"
                          >Create a Free Account</a
                        >
                        and then..
                      </p>

                      <a class="ng-latest-supporter-button" href="/"
                        >Become a Supporter!</a
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="header-deck">
                <div class="ng-latest-supporter-wide">
                  <amp-ad
                    width="100vw"
                    height="320"
                    type="adsense"
                    :data-ad-client="clientId"
                    :data-ad-slot="clientslotId"
                    data-auto-format="rspv"
                    data-full-width=""
                  >
                    <div overflow=""></div>
                  </amp-ad>
                  <div id="ad-container" align="center"></div>
                </div>
              </div>
              <input
                type="hidden"
                id="submission_url"
                value="https://www.newgrounds.com/portal/view/965654"
              />

              <div
                class="column"
                id="content_area"
                itemscope
                itemtype="https://schema.org/WebApplication"
              >
                <meta
                  itemprop="datePublished"
                  content="2025-01-23T02:08:10-05:00"
                />
                <meta
                  itemprop="url"
                  content="https://www.newgrounds.com/portal/view/965654"
                />

                <meta
                  itemprop="thumbnailUrl"
                  content="https://picon.ngfiles.com/965000/flash_965654_card.webp?f1737616096"
                />
                <meta itemprop="description" content="run and fun!!" />
                <meta itemprop="applicationCategory" content="Game" />

                <div class="pod embed">
                  <div class="pod-head" id="embed_header">
                    <h2 class="rated-e" itemprop="name">PINSKI</h2>

                    <div
                      itemprop="aggregateRating"
                      itemscope
                      itemtype="https://schema.org/AggregateRating"
                    >
                      <meta itemprop="worstRating" content="0" />
                      <meta itemprop="bestRating" content="10" />
                      <meta itemprop="ratingCount" content="119" />
                      <meta itemprop="ratingValue" content="8.5528378942" />

                      <span>
                        <a
                          class="icon-share"
                          href="/"
                          id="share_content"
                          rel="nofollow"
                          title="Share"
                          >Share</a
                        >
                      </span>

                      <span style="display: none">
                        <a
                          class="icon-min"
                          href="/"
                          title="Collapse"
                          rel="nofollow"
                          id="collapse_item"
                          >Collapse</a
                        >
                      </span>
                    </div>
                  </div>

                  <div class="pod-body breakout" id="embed_podcontent">
                    <div id="embed_wrapper">
                      <iframe
                        id="gameFrame"
                        class="game-frame"
                        :src="frameUrl"
                        title="War Checkers"
                        frameborder="0"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column wide right">
                <div>
                  <div class="pod" id="api_features">
                    <div class="pod-head">
                      <h2 class="" id="api_label">Medals</h2>
                      <div id="api_buttons">
                        <span>
                          <a
                            data-href="/"
                            class="active"
                            id="api_feature_medals"
                            rel="nofollow"
                          >
                            Medals
                          </a>
                        </span>
                        <span>
                          <a href="/" id="api_feature_scores" rel="nofollow">
                            High Scores
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      class="pod-body gamesupport-medals"
                      id="medals_for_59606"
                    >
                      <p>
                        <a href="/">Log in</a> to save your medals! Don't have
                        an account?
                        <a href="/">Create one for free!</a>
                      </p>
                    </div>
                    <div class="pod-body text-align-center">
                      <a href="/">Global Rankings</a>

                      |
                      <a href="/">More Games With Medals</a>
                    </div>
                  </div>
                </div>

                <div class="pod">
                  <div class="pod-head">
                    <h2 class="document">Author Comments</h2>
                  </div>

                  <div class="pod-body ql-body" id="author_comments">
                    <p>happy pixel day 2025!</p>
                    <p><br /></p>
                    <p>
                      this is a game i made in about 5 weeks (although really it
                      was more like 3 or 4). you have to run away from a giant
                      rolling ball of garbage while placing blocks and other
                      objects to help you avoid obstacles. the game has 3 levels
                      and it goes on forever, speeding up every time you
                      complete a loop. go for the high score! : )
                    </p>
                    <p><br /></p>
                    <p>controls are:</p>
                    <p>
                      <strong>[A / left arrow] and [D / right arrow] </strong>to
                      walk
                    </p>
                    <p><strong>[Z / space] </strong>to jump</p>
                    <p>
                      <strong>[X / enter] </strong>to open or close the block
                      editor
                    </p>
                    <p>
                      <strong>[WASD / arrow keys] </strong>to move blocks around
                    </p>
                    <p><strong>[Z / space] </strong>to place a block</p>
                    <p>
                      <strong>[Q / left shift] and [E / right shift] </strong>to
                      change block type
                    </p>
                    <p>(all of these binds can be changed)</p>
                    <p><br /></p>
                    <p>
                      this is the first serious project i've done where i did
                      all the programming, artwork, and music myself (except for
                      the thumbnail which was done by
                      <a href="/">@pkettles</a>!). it was very hectic but i like
                      how it came together. not sure if i'd do it again unless i
                      had a lot of time, but i had fun making it even despite
                      getting pretty burnt out at the end. enjoy pinski!
                    </p>
                  </div>
                </div>

                <div class="pod">
                  <div class="pod-body text-align-center">
                    <h3>
                      <a href="/" id="login_prompt">Log in</a> /
                      <a href="/" id="signup_prompt">sign up</a> to vote &amp;
                      review!
                    </h3>
                  </div>
                </div>

                <div class="pod" id="portal67937532b57a3">
                  <div class="pod-body">
                    <div class="pagenav" id="review-navigation-portal-top">
                      <div class="align-right">
                        <a rel="nofollow"><span>1</span></a>

                        <a href="/" rel="nofollow"><span>2</span></a>
                      </div>

                      <div class="sort">
                        <span>Sort By:</span>

                        <strong>Date</strong>
                        <a href="/">Score</a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="pod-body review"
                    id="review_1807011067937532b5899"
                    data-review-id="18070110"
                  >
                    <div class="review-meta">
                      <div>
                        <a href="/" title="alsoknownas1">
                          <svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-45 sz-large s0 c0"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/7638/7638128_large.png?f1690891455"
                            />
                            <use href="#path_s0" />
                          </svg>
                          <span class="padded-half-left">alsoknownas1</span>
                        </a>
                      </div>

                      <time id="review_time_18070110">2025-01-24 03:19:20</time>

                      <span class="score">
                        <div class="star-score" title="Score: 5.00/5.00">
                          <span style="width: 100%"></span>
                        </div>
                      </span>
                    </div>

                    <div
                      class="review-body text-content"
                      id="review_body_18070110"
                    >
                      <p>
                        Really fun idea and great execution. I'd love to see you
                        do something bigger with it, but it's a great little
                        game as is.
                      </p>
                    </div>
                  </div>
                  <div
                    class="pod-body review"
                    id="review_1807007167937532b6e97"
                    data-review-id="18070071"
                  >
                    <div class="review-meta">
                      <div>
                        <a href="/" title="KittyhawkMontrose">
                          <svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-45 sz-large s5 c16"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/7630/7630498_large.png?f1669089666"
                            />
                            <use href="#path_s5" />
                          </svg>
                          <span class="padded-half-left"
                            >KittyhawkMontrose</span
                          >
                        </a>
                      </div>

                      <time id="review_time_18070071">2025-01-24 02:42:39</time>

                      <span class="score">
                        <div class="star-score" title="Score: 5.00/5.00">
                          <span style="width: 100%"></span>
                        </div>
                      </span>
                    </div>

                    <div
                      class="review-body text-content"
                      id="review_body_18070071"
                    >
                      <p>
                        I gotta get better! Really interesting game play here.
                        😃
                      </p>
                    </div>
                  </div>
                  <div
                    class="pod-body review"
                    id="review_1806903267937532b83d7"
                    data-review-id="18069032"
                  >
                    <div class="review-meta">
                      <div>
                        <a href="/" title="poptaffy">
                          <svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-45 sz-large s9 c2"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/5065/5065110_large.png?f1649109733"
                            />
                            <use href="#path_s9" />
                          </svg>
                          <span class="padded-half-left">poptaffy</span>
                        </a>
                      </div>

                      <time id="review_time_18069032">2025-01-23 19:26:57</time>

                      <span class="score">
                        <div class="star-score" title="Score: 5.00/5.00">
                          <span style="width: 100%"></span>
                        </div>
                      </span>
                    </div>

                    <div
                      class="review-body text-content"
                      id="review_body_18069032"
                    >
                      <p>
                        the sound work is wonderful....I like clicking and
                        hitting everything......also the little guy design
                        rules.
                      </p>

                      <p>I will win PINSKI</p>
                    </div>
                  </div>
                  <div
                    class="pod-body review"
                    id="review_1806886867937532b98e2"
                    data-review-id="18068868"
                  >
                    <div class="review-meta">
                      <div>
                        <a href="/" title="Goji">
                          <svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-45 sz-large s0 cX"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/8009/8009203_large.webp?f1706148139"
                            />
                            <use href="#path_s0" />
                          </svg>
                          <span class="padded-half-left">Goji</span>
                        </a>
                      </div>

                      <time id="review_time_18068868">2025-01-23 18:38:24</time>

                      <span class="score">
                        <div class="star-score" title="Score: 5.00/5.00">
                          <span style="width: 100%"></span>
                        </div>
                      </span>
                    </div>

                    <div
                      class="review-body text-content"
                      id="review_body_18068868"
                    >
                      <p>
                        this turned out amazing :) im a big katamari fan so i
                        love balls of garbage
                      </p>
                    </div>
                  </div>
                  <div
                    class="pod-body review"
                    id="review_1806756667937532ba787"
                    data-review-id="18067566"
                  >
                    <div class="review-meta">
                      <div>
                        <a href="/" title="DestriousCreation">
                          <svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-45 sz-large s6 c9"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/8824/8824459_large.webp?f1737149662"
                            />
                            <use href="#path_s6" />
                          </svg>
                          <span class="padded-half-left"
                            >DestriousCreation</span
                          >
                        </a>
                      </div>

                      <time id="review_time_18067566">2025-01-23 10:50:30</time>

                      <span class="score">
                        <div class="star-score" title="Score: 5.00/5.00">
                          <span style="width: 100%"></span>
                        </div>
                      </span>
                    </div>

                    <div
                      class="review-body text-content"
                      id="review_body_18067566"
                    >
                      <p>
                        I really like the gimmick of the game I had a lot of fun
                        playing this!
                      </p>
                    </div>
                  </div>
                  <div class="pod-body">
                    <div class="pagenav" id="review-navigation-portal-bottom">
                      <a rel="nofollow"><span>1</span></a>

                      <a href="/" rel="nofollow"><span>2</span></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column thin">
                <div class="pod">
                  <div class="pod-head">
                    <h2 class="users">Credits &amp; Info</h2>
                  </div>

                  <div data-users-and-credits-for="6362212">
                    <div
                      class="pod-body"
                      id="authorlinks6362212"
                      data-authorlinks="6362212"
                    >
                      <div class="authorlinks">
                        <div class="item-user">
                          <a class="item-icon" href="/">
                            <div class="user-icon-bordered">
                              <svg
                                viewBox="0 0 248 248"
                                alt="Jack"
                                class="svgmask sz-large s6 c3"
                              >
                                <image
                                  href="https://uimg.ngfiles.com/icons/7594/7594542_large.jpg?f1649114137"
                                />
                                <use href="#path_s6" />
                              </svg>
                            </div>
                          </a>

                          <div class="item-details">
                            <div class="item-details-main">
                              <h4>
                                <a href="/">Jack</a>
                              </h4>
                            </div>
                            <div class="item-details-meta">
                              <div class="role">
                                <em>Author</em>
                              </div>
                            </div>
                          </div>
                          <div class="item-follow-user">
                            <span
                              class="favefollow-buttons"
                              id="ffr_ffr_67937532be658_1"
                            >
                              <span class="favefollow-add">
                                <a href="#" title="Follow" class="follow-user"
                                  ><span>Follow</span></a
                                >
                              </span>
                            </span>
                          </div>
                        </div>
                        <div class="item-user">
                          <a class="item-icon" href="/">
                            <div class="user-icon-bordered">
                              <svg
                                viewBox="0 0 248 248"
                                alt="PKettles"
                                class="svgmask sz-large s9 c15"
                              >
                                <image
                                  href="https://uimg.ngfiles.com/icons/7510/7510690_large.webp?f1704424932"
                                />
                                <use href="#path_s9" />
                              </svg>
                            </div>
                          </a>

                          <div class="item-details">
                            <div class="item-details-main">
                              <h4>
                                <a href="/">PKettles</a>
                              </h4>
                            </div>
                            <div class="item-details-meta">
                              <div class="role">
                                <em>Additional Art</em>
                              </div>
                            </div>
                          </div>
                          <div class="item-follow-user">
                            <span
                              class="favefollow-buttons"
                              id="ffr_ffr_67937532be658_2"
                            >
                              <span class="favefollow-add">
                                <a href="/" title="Follow" class="follow-user"
                                  ><span>Follow</span></a
                                >
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pod-body">
                      <ul class="authorlinks">
                        <li>
                          <div class="audio-wrapper">
                            <div class="item-audiosubmission-small">
                              <a href="/" class="item-link">
                                <div class="item-icon">
                                  <img
                                    src="https://aicon.ngfiles.com/1394/1394122.webp?f1737425707"
                                    alt="PINSKI OST"
                                    width="90"
                                    height="90"
                                  />
                                </div>
                                <h4 class="detail-title">
                                  <span> PINSKI OST </span>
                                  <span>Music</span>
                                </h4>
                              </a>
                            </div>

                            <a
                              class="play-control-small"
                              title="PLAY"
                              data-audio-playback="1394122"
                              data-audio-type="3"
                              data-audio-duration="276"
                              style="display: none"
                              >PLAY</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="pod-body">
                    <div id="sidestats">
                      <dl class="sidestats" data-statistics="965654_2">
                        <dt>Views</dt>
                        <dd>1,086</dd>

                        <dt>Faves:</dt>
                        <dd>
                          <a href="/" id="faves_load">41</a>
                        </dd>

                        <dt>Votes</dt>
                        <dd>119</dd>

                        <dt>Score</dt>
                        <dd>
                          <span id="score_number">4.28</span> / 5.00
                          <span id="submission_score_change"></span><br />
                          <span class="star-variable"
                            ><span style="width: 85.6%"></span
                          ></span>
                        </dd>
                      </dl>

                      <hr />

                      <dl class="sidestats">
                        <dt>Uploaded</dt>
                        <dd>Jan 23, 2025</dd>
                        <dd>2:08 AM EST</dd>

                        <div
                          class="flexbox align-center"
                          id="genre-view-67937532c2934"
                        >
                          <dl
                            class="sidestats flex-1"
                            style="position: relative"
                          >
                            <dt>Genre</dt>
                            <dd>
                              <a href="/" data-genre-for="6362212"
                                >Action - Platformer - Other</a
                              >
                            </dd>
                          </dl>
                        </div>
                      </dl>

                      <div
                        class="flexbox align-center"
                        id="item-tags-67937532c2e97"
                      >
                        <dl class="sidestats flex-1">
                          <dt class="tags">Tags</dt>
                          <dd class="tags">
                            <ul>
                              <li>
                                <a href="/">infiniterunner</a>
                              </li>
                              <li>
                                <a href="/">pinski</a>
                              </li>
                              <li>
                                <a href="/">pixelday2025</a>
                              </li>
                              <li>
                                <a href="/">pixelday2025-compo</a>
                              </li>
                              <li>
                                <a href="/">platformer</a>
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </div>

                      <div
                        class="flexbox align-center"
                        id="equipment_list67937532c31de"
                      >
                        <dl class="sidestats flex-1"></dl>
                      </div>
                    </div>
                  </div>

                  <div class="pod-body">
                    <ul class="trophies">
                      <li class="frontpage">
                        <div class="flex-1 padded-vert">
                          <strong>Frontpaged</strong>
                          <a href="/">January 23, 2025</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="pod" id="related_playlists67937532c348a">
                  <div class="pod-head">
                    <h2>Plenty more like this here!</h2>
                  </div>
                  <div class="pod-body">
                    <ul class="itemlist alternating">
                      <li>
                        <a
                          href="/"
                          class="item-collection"
                          title="Christmas 2024"
                        >
                          <div class="item-icon">
                            <div>
                              <img
                                src="https://img.ngfiles.com/playlists/sleeves/418000/418233.webp?f1733241931
                              width='60' height='60' alt='Christmas2024'"
                              />
                            </div>
                          </div>
                          <div class="item-details">
                            <div class="item-details-main">
                              <div class="detail-title">
                                <h4>Christmas 2024</h4>
                              </div>
                              <div class="detail-description">
                                Movies and games for the season!
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        class="alternate padded-1point5 margin-1point5-top text-align-center"
                      >
                        <span class="large-size"
                          ><a id="related_playlists67937532c348a-url" href="/"
                            >Featured in 1 Lists</a
                          ></span
                        >
                      </li>
                    </ul>
                  </div>
                </div> -->
              </div>
              <div style="clear: both"></div>
            </div>
            <div class="header-deck">
              <div class="ng-latest-supporter-wide">
                <amp-ad
                  width="100vw"
                  height="320"
                  type="adsense"
                  :data-ad-client="clientId"
                  :data-ad-slot="clientslotId"
                  data-auto-format="rspv"
                  data-full-width=""
                >
                  <div overflow=""></div>
                </amp-ad>
                <div id="ad-container2" align="center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="body-footer-shim"></div>
      <div class="body-footer-wrapper">
        <div class="footer-features">
          <div id="footer-featur  e-buttons" class="footer-feature-buttons">
            <span
              ><h3><span>FEATURED</span><span>CONTENT</span></h3>
              <div>
                <!-- <button
                  id="footer-feature-btn-games"
                  class="footer-feature-btn-games"
                >
                  Games
                </button> -->
                <button
                  v-for="(item, index) in meunList"
                  :key="index"
                  :class="`footer-feature-btn-${item.name.toLowerCase()}`"
                  :id="`footer-feature-btn-${item.name.toLowerCase()}`"
                  @click="handleClickBottomBtn"
                >
                  {{ item.name }}
                </button>
              </div>
            </span>
          </div>
          <!-- <div class="footer-features-items" id="footer-features-items">
            <div>
              <a class="featureshift-left" id="featureshift-left"
                ><span></span
              ></a>
            </div>
            <div class="featured-items" id="footer-featured-items">
              <div class="footer-features-blogposts">
                <div class="span-1">
                  <a href="/"> VidGuy20 <strong>guess wat....</strong> </a>
                </div>
                <div class="span-1">
                  <a href="/">
                    sackydzNG <strong>finally deleted reddit!</strong>
                  </a>
                </div>
                <div class="span-1">
                  <a href="/"> VidGuy20 <strong>hello ppl!</strong> </a>
                </div>
                <div class="span-1">
                  <a href="/"> DreadOrpheus <strong>Hi!</strong> </a>
                </div>
                <div class="span-1">
                  <a href="/"> SirArtanis <strong>bro!!!</strong> </a>
                </div>
                <div class="span-1">
                  <a href="/">
                    NationalsCreationals <strong>Art Posting</strong>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <a class="featureshift-right" id="featureshift-right"
                ><span></span
              ></a>
            </div>
          </div> -->
        </div>

        <div style="clear: both"></div>
        <div class="footer-main">
          <div class="footer-background">
            <div class="footer-wall-artist">
              <a class="flexbox inline align-center no-visited" href="/">
                <div class="flex-1 text-align-right">
                  <strong class="highlight">Wall Art by</strong>
                </div>
                <div class="padded">
                  <!-- <img :src="bottomLogo" /> -->
                </div>
                <div class="flex-1">
                  <strong>{{ proName }}</strong>
                </div>
              </a>
            </div>
            <div class="footer-navigation no-visited">
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Main Sections</h4>
                  <ul>
                    <li v-for="(item, index) in meunList" :key="index">
                      <a :href="item.skipUrl">{{ item.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Extra, Extra!</h4>
                  <ul>
                    <li>
                      <a href="/">Series</a>
                    </li>
                    <li>
                      <a href="/">Collections</a>
                    </li>
                    <li>
                      <a href="/">Playlists</a>
                    </li>
                    <li>
                      <a href="/">Game Judging</a>
                    </li>
                    <li>
                      <a href="/">Movie Judging</a>
                    </li>
                    <li>
                      <a href="/">Classic Portal</a>
                    </li>
                    <li>
                      <a href="/" target="_blank">Snag a T-Shirt</a>
                    </li>
                    <li>
                      <a href="/">Creator Resources</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Community</h4>
                  <ul>
                    <li><a href="/">Forums</a></li>
                    <li><a href="/">Chat</a></li>
                    <li>
                      <a href="/">Calendar</a>
                    </li>
                    <li>
                      <a href="/">Artist News</a>
                    </li>
                    <li>
                      <a href="/">Rankings</a>
                    </li>
                    <li>
                      <a href="/">NG Wiki</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>NG Related</h4>
                  <ul>
                    <li>
                      <a href="/">About</a>
                    </li>
                    <li>
                      <a href="/">Help / FAQ</a>
                    </li>
                    <li>
                      <a href="/">Contact Us</a>
                    </li>
                    <li>
                      <a href="/">Support NG!</a>
                    </li>
                    <li>
                      <a href="/">The Staff</a>
                    </li>
                    <li>
                      <a href="/">History</a>
                    </li>
                    <li>
                      <a href="/">RSS</a>
                    </li>
                    <li>
                      <a href="/">Copyright</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="footer-decoration"></div>
          </div>
          <div class="footer-bottom">
            <div class="footer-copyright no-visited">
              ©Copyright 1995-2025 Newgrounds, Inc. All rights reserved.
              <a :href="`https://${proName}.com/game/privacy-policy.html`">Privacy Policy</a>
              |
              <a :href="`https://${proName}.com/game/terms-of-service.html`">Terms of Use</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, created, onMounted } from "vue";
// 菜单列表
const meunList = [
  {
    name: "Movies",
    skipUrl: "/",
    cn: "movies",
  },
  {
    name: "Games",
    skipUrl: "/play",
  },
  {
    name: "Audio",
    skipUrl: "/",
  },
  {
    name: "Art",
    skipUrl: "/",
  },
  {
    name: "Portal",
    skipUrl: "/",
  },
  {
    name: "Community",
    skipUrl: "/",
  },
];

const handleClickBottomBtn = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const topLogoStyle2 = require("@/assets/image/logo-white.png");
const bottomLogo = require("@/assets/image/logo.jpg");

const gameId = ref("");
const frameUrl = ref("");
const navigateToGame = (index) => {
  router.push({ name: "play", query: { gameId: index } });
  getGameIdFromUrl();
};
const getGameIdFromUrl = () => {
  const urlParams = window.location.search;
  // 去掉查询字符串开头的 ?
  const paramsArray = urlParams.slice(1).split("&");

  // 查找 gameId 参数
  const gameIdParam = paramsArray.find((param) => param.startsWith("gameId="));

  if (gameIdParam) {
    const gameIdValue = gameIdParam.split("=")[1];

    if (gameIdValue) {
      gameId.value = gameIdValue;
      frameUrl.value = `https://xmxmy.com/gameTesting/${gameIdValue}/index.html`;
      console.log(frameUrl.value, "frameUrl.value");
    }
  }
};
const proName = process.env.VUE_APP_PROJECTNAME;

/**
 * adsense
 */
const clientId = process.env.VUE_APP_ADSENSEID;
const clientslotId = process.env.VUE_APP_ADSENSESLOTID;

const loadAds = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.VUE_APP_ADSENSEID}`;
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "block";

  ins.setAttribute("data-ad-client", process.env.VUE_APP_ADSENSEID);
  ins.setAttribute("data-ad-slot", process.env.VUE_APP_ADSENSESLOTID);
  const adContainer = document.getElementById("ad-container");
  if (adContainer) {
    adContainer.appendChild(script);
    adContainer.appendChild(ins);

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000);
  } else {
    console.error("Ad container not found!");
  }
};
const loadAds2 = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.VUE_APP_ADSENSEID}`;
  script.crossOrigin = "anonymous";

  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "block";
  ins.setAttribute("data-ad-client", process.env.VUE_APP_ADSENSEID);
  ins.setAttribute("data-ad-slot", process.env.VUE_APP_ADSENSESLOTID);

  const adContainer = document.getElementById("ad-container2");
  if (adContainer) {
    adContainer.appendChild(script);
    adContainer.appendChild(ins);

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000);
  } else {
    console.error("Ad container not found!");
  }
};
const isMobile = ref(checkIsMobile());

function checkIsMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}
function handleResize() {
  isMobile.value = checkIsMobile();
}
onMounted(() => {
  getGameIdFromUrl();
  loadAds();
  loadAds2();
  // 初始化时先判断一次
  handleResize();
  handleClickBottomBtn();

  // 监听窗口大小变化
  window.addEventListener("resize", handleResize);
});
</script>

<style lang="scss">
#gameFrame {
  width: 100%;
  min-height: 500px;
}
</style>
