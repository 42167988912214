<template>
  <div class="home-box">
    <div class="page-dimmer"></div>
    <div id="activetask-saving-msg" style="display: none">
      <em class="fa fa-spin fa-spinner"></em> Saving
    </div>
    <div id="passport_container" class="passport-container"></div>

    <div
      class="blackout-hover"
      id="blackout_hover"
      style="display: none; overflow: hidden"
    >
      <div class="blackout-inner">
        <div class="blackout-bookend"></div>
        <div class="blackout-bookshelf">
          <div class="blackout-bookend shimmed"></div>
          <div class="blackout-center" id="blackout_center"></div>
          <div class="blackout-bookend shimmed"></div>
        </div>
        <div class="blackout-bookend"></div>
      </div>
    </div>
    <div class="blackout" id="blackout">
      <div class="blackout-bg" id="blackout-bg"></div>
    </div>
    <div id="_ngHiddenAudioPlayerWrapper" class="ng-audio-player-global">
      <audio id="global-audio"></audio>

      <div class="ng-apg-centered">
        <div id="_ngHiddenAudioPlayer" class="ng-apg-bounds">
          <div class="media-player-playback-controls">
            <button
              class="media-player-previous"
              id="global-audio-player-previous"
              title="Previous Track"
              disabled
            >
              <i class="fa fa-step-backward" role="presentation"></i>
            </button>

            <button
              class="media-player-play"
              id="global-audio-player-play"
              title="Play"
              disabled
            >
              <i class="fa fa-play" role="presentation"></i>
            </button>

            <button
              class="media-player-pause"
              id="global-audio-player-pause"
              title="Pause"
              disabled
            >
              <i class="fa fa-pause" role="presentation"></i>
            </button>

            <button
              class="media-player-stop"
              id="global-audio-player-stop"
              title="Stop"
              disabled
            >
              <i class="fa fa-stop" role="presentation"></i>
            </button>

            <button
              class="media-player-next"
              id="global-audio-player-next"
              title="Next Track"
              disabled
            >
              <i class="fa fa-step-forward" role="presentation"></i>
            </button>

            <span class="spacer"></span>

            <button
              class="media-player-repeat"
              id="global-audio-player-repeat"
              title="Repeat All Tracks"
              disabled
            >
              <i class="fa fa-retweet" role="presentation"></i>
            </button>
          </div>
          <div class="media-player-progress">
            <div
              class="media-player-current-time"
              id="global-audio-player-progress"
            >
              00:00
            </div>

            <div class="media-player-progression">
              <div class="position" id="global-audio-player-container"></div>
            </div>

            <div
              class="media-player-duration"
              id="global-audio-player-duration"
            >
              00:00
            </div>
          </div>

          <div class="media-player-volume-control">
            <button id="global-audio-player-volumeToggle">
              <i class="fa fa-volume-up"></i>
            </button>

            <div class="bar off" id="global-audio-player-volume"></div>
          </div>
          <div
            id="_ngHiddenAudioPlayerDetails"
            class="media-player-display"
          ></div>
        </div>
      </div>
    </div>
    <div id="notification-shim" class="notification-shim"></div>
    <div class="notification-bar" id="notification-bar" v-if="!isMobile">
      <div class="wrapper">
        <div class="logo padded-left">
          <!-- <img :src="topLogoStyle2" /> -->
          <!-- <a href="" :style="topLogoStyle">Newgrounds</a> -->
        </div>
        <div class="search" role="search">
          <form id="topsearch-elastic" action="" method="get">
            <input type="hidden" name="suitabilities" value="etm" />
            <input type="hidden" name="c" value="2" />

            <input
              placeholder="Search Games..."
              type="text"
              name="terms"
              id="topsearch_text"
              value=""
              maxlength="40"
              aria-label="Search Games..."
            /><button type="submit" class="topsearch-submit" title="Search">
              <em class="fa fa-search"></em>
            </button>
          </form>
        </div>
        <div class="notifications padded-right" id="notification-buttons">
          <div class="notification-login-side">
            <a href="" rel="nofollow" id="passport_login">Login</a>
            /
            <a href="" rel="nofollow" id="passport_create">Sign Up</a>
          </div>
        </div>
      </div>
    </div>
    <div
      id="notification-bar"
      class="mobile-header-container"
      style="position: absolute; top: 0px"
      v-else
    >
      <div class="mobile-header-bar grid-align-middle">
        <div>
          <div class="topbar-menu">
            <a
              id="mobile_main_menu_btn"
              class="icon-menu-open"
              title="Menu"
            ></a>
            <a href="/" class="logo" title="Home" :style="logoStyle"></a>
          </div>
        </div>
        <div class="notification-align text-align-center">
          <div>
            <a
              class="mobile-top-login"
              href="/"
              id="passport_login"
              rel="nofollow"
              >Login</a
            >
            /
            <a
              class="mobile-top-login"
              href="/"
              id="passport_create"
              rel="nofollow"
              >Sign Up</a
            >
          </div>
        </div>
        <div class="notification-search align-right" v-if="!isMobile">
          <div class="topbar-menu">
            <a
              href="/"
              id="mobile_main_search_btn"
              class="icon-search"
              title="Search"
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="outer" id="outer-skin">
      <div class="header-nav-row" id="header-nav-row">
        <div
          class="header-nav-container header-nav-relative"
          id="header-nav-container"
        >
          <nav id="topnav" class="header-nav-buttons">
            <a
              href="/"
              :class="`header-nav-button-${item.name.toLowerCase()}`"
              v-for="(item, index) in meunList"
              :key="index"
              >{{ item.name }}</a
            >
          </nav>
        </div>
      </div>

      <div id="ng-global-video-player-shimwrap">
        <div id="ng-global-video-player-shim"></div>
      </div>
      <div id="ng-global-video-player-wrapper">
        <div id="ng-global-video-player"></div>
      </div>
      <div class="skin-wrapper" v-if="!isMobile">
        <div class="skin-inner">
          <div
            class="background"
            style="
              background-image: url('//img.ngfiles.com/themes/000/f54496cf1734535158.webp?1734535162');
            "
          >
            <img
              src="//img.ngfiles.com/themes/000/f54496cf1734535158.webp?1734535162"
              alt="Newgrounds Background Image Theme"
            />
          </div>
          <div class="skin-bars"></div>
        </div>
      </div>

      <div id="header-insert"></div>

      <div class="body-main">
        <div class="body-center">
          <div>
            <div class="body-guts top">
              <!-- <div class="header-deck">
                <div class="ng-latest-supporter-wide">
                  <div class="ng-latest-supporter-inner">
                    <div class="ng-latest-supporter-image">
                      <div class="user-icon-bordered">
                        <a
                          href="https://jeffersonparsnip.newgrounds.com"
                          target="_blank"
                          ><svg
                            viewBox="0 0 248 248"
                            class="svgmask sz-large s0 c3"
                          >
                            <image
                              href="https://uimg.ngfiles.com/icons/24242/24242310_large.webp?f1730895934"
                            />
                            <use href="#path_s0" /></svg
                        ></a>
                      </div>
                    </div>
                    <div class="ng-latest-supporter-meta" style="flex: 1">
                      <p class="ng-latest-supporter-title">
                        <a
                          href="https://jeffersonparsnip.newgrounds.com"
                          target="blank"
                          >JeffersonParsnip</a
                        >
                        just joined the crew!
                      </p>
                      <p class="ng-latest-supporter-message">
                        We need <strong>you</strong> on the team, too.
                      </p>
                      <p class="ng-latest-supporter-message">
                        Support Newgrounds and get tons of perks for just $2.99!
                      </p>
                    </div>
                    <div class="ng-latest-supporter-meta">
                      <p style="text-align: center; padding-bottom: 4px">
                        <a
                          href="#"
                          id="supporter_message_pp_open"
                          rel="nofollow"
                          >Create a Free Account</a
                        >
                        and then..
                      </p>

                      <a class="ng-latest-supporter-button" href=""
                        >Become a Supporter!</a
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="header-deck">
                <div class="ng-latest-supporter-wide">
                  <amp-ad
                    width="100vw"
                    height="320"
                    type="adsense"
                    :data-ad-client="clientId"
                    :data-ad-slot="clientslotId"
                    data-auto-format="rspv"
                    data-full-width=""
                  >
                    <div overflow=""></div>
                  </amp-ad>
                  <div id="ad-container" align="center"></div>
                </div>
              </div>
              <div class="column" id="hub">
                <div class="pod">
                  <div class="pod-head">
                    <h2 class="game">Featured Games</h2>
                    <span>
                      <a href="">Game Dev Forum</a>
                    </span>
                  </div>
                  <form method="post" action="">
                    <div class="pod-body">
                      <div class="searchform-options padded-bottom">
                        <div class="searchform-sort">
                          <a href="" class="no-visited current"> Featured </a>

                          <a href="" class="no-visited"> Latest </a>

                          <a href="" class="no-visited"> Popular </a>

                          <a href="" class="no-visited"> Your Feed </a>
                        </div>
                        <div class="searchform-extra">
                          <div class="flexbox align-center">
                            <label class="ratings-label padded-right strong em"
                              >Ratings:</label
                            >
                            <div>
                              <ul
                                class="horzlinks suitability-checkboxes"
                                id="suitabilities6786592f5beda"
                              >
                                <li v-for="item in ratingGrade" :key="item.id">
                                  <input
                                    type="checkbox"
                                    :class="`suitable-${item.value}`"
                                    name="suitabilities[]"
                                    :id="`suitabilities6786592f5beda_${item.value}`"
                                    :value="item.value"
                                    v-model="item.checkedFlag"
                                  />
                                  <label
                                    :for="`suitabilities6786592f5beda_${item.value}`"
                                    >{{ item.value }}</label
                                  >
                                </li>
                                <!-- <li>
                                  <input
                                    type="checkbox"
                                    class="suitable-e"
                                    name="suitabilities[]"
                                    id="suitabilities6786592f5beda_e"
                                    value="e"
                                    checked="checked"
                                  />
                                  <label for="suitabilities6786592f5beda_e"
                                    >e</label
                                  >
                                </li>

                                <li>
                                  <input
                                    type="checkbox"
                                    class="suitable-t"
                                    name="suitabilities[]"
                                    id="suitabilities6786592f5beda_t"
                                    value="t"
                                    checked="checked"
                                  />
                                  <label for="suitabilities6786592f5beda_t"
                                    >t</label
                                  >
                                </li>

                                <li>
                                  <input
                                    type="checkbox"
                                    class="suitable-m"
                                    name="suitabilities[]"
                                    id="suitabilities6786592f5beda_m"
                                    value="m"
                                    checked="checked"
                                  />
                                  <label for="suitabilities6786592f5beda_m"
                                    >m</label
                                  >
                                </li>

                                <li data-disabled="">
                                  <input
                                    type="checkbox"
                                    class="suitable-a"
                                    name="suitabilities[]"
                                    id="suitabilities6786592f5beda_a"
                                    value="a"
                                    disabled
                                  />
                                  <label for="suitabilities6786592f5beda_a"
                                    >a</label
                                  >
                                </li> -->

                                <input
                                  type="hidden"
                                  name="userkey"
                                  value="91df9%O575dce82f912%7483O7d4d075d04%Pba8f5r3f%%2e4924%7%9%113%4758s0fd062edO4a62r9776rs%1696fc%%s2%3%e3ed35d2085r6P4f07%d0rP1a0c598r06e06eab28e914d426938429b15f15665%s%4d7P%%O%rr1562-3410632"
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flexbox padded-top form-inline">
                        <div>
                          <label>Range:</label>
                          <div class="select-wrapper">
                            <select name="interval">
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="week">Week</option>
                              <option value="month">Month</option>
                              <option value="year">Year</option>
                              <option value="all" selected="selected">
                                All
                              </option>
                              <option value="">Custom</option>
                            </select>
                          </div>
                        </div>

                        <div class="flex-1"></div>

                        <div>
                          <label>Sort By:</label>
                          <div class="select-wrapper">
                            <select name="sort">
                              <option value="date" selected="selected">
                                Date
                              </option>
                              <option value="score">Score</option>
                              <option value="views">Views</option>
                            </select>
                          </div>
                        </div>

                        <div class="flex-1"></div>

                        <div>
                          <label>Category:</label>
                          <div class="select-wrapper">
                            <select name="genre">
                              <option value="0" selected="selected">All</option>
                              <option value="40">
                                Action - Fighting - Brawler
                              </option>
                              <option value="39">Action - Fighting - VS</option>
                              <option value="72">
                                Action - Platformer - Hop and Bop
                              </option>
                              <option value="71">
                                Action - Platformer - Puzzle
                              </option>
                              <option value="73">
                                Action - Platformer - Other
                              </option>
                              <option value="44">
                                Action - Shooter - First Person
                              </option>
                              <option value="64">
                                Action - Shooter - Fixed
                              </option>
                              <option value="41">
                                Action - Shooter - Horizontal Flight
                              </option>
                              <option value="63">
                                Action - Shooter - Multidirectional
                              </option>
                              <option value="10">
                                Action - Shooter - Run &#039;n Gun
                              </option>
                              <option value="43">
                                Action - Shooter - Tube / Rail
                              </option>
                              <option value="42">
                                Action - Shooter - Vertical Flight
                              </option>
                              <option value="12">Action - Other</option>
                              <option value="13">
                                Adventure - Point &#039;n Click
                              </option>
                              <option value="14">Adventure - RPG</option>
                              <option value="15">Adventure - Other</option>
                              <option value="16">
                                Gadgets - Construction Set
                              </option>
                              <option value="62">Gadgets - Dress Up</option>
                              <option value="17">Gadgets - Musical</option>
                              <option value="75">Gadgets - Soundboards</option>
                              <option value="18">Gadgets - Webcam</option>
                              <option value="19">Gadgets - Other</option>
                              <option value="76">Idle / Incremental</option>
                              <option value="74">Puzzles - Difference</option>
                              <option value="21">Puzzles - Falling</option>
                              <option value="59">Puzzles - Quiz</option>
                              <option value="22">Puzzles - Sliding</option>
                              <option value="23">Puzzles - Other</option>
                              <option value="5">Rhythm</option>
                              <option value="67">Simulation - Dating</option>
                              <option value="69">Simulation - Job</option>
                              <option value="68">
                                Simulation - Pet / Buddy
                              </option>
                              <option value="70">Simulation - Other</option>
                              <option value="24">Skill - Avoid</option>
                              <option value="25">Skill - Collect</option>
                              <option value="26">Skill - Toss</option>
                              <option value="27">Skill - Typing</option>
                              <option value="28">Skill - Other</option>
                              <option value="56">Spam</option>
                              <option value="29">Sports - Basketball</option>
                              <option value="30">Sports - Boxing</option>
                              <option value="31">
                                Sports - Casino &amp; Gambling
                              </option>
                              <option value="32">Sports - Golf</option>
                              <option value="33">Sports - Racing</option>
                              <option value="34">Sports - Soccer</option>
                              <option value="35">Sports - Other</option>
                              <option value="65">Strategy - Artillery</option>
                              <option value="66">
                                Strategy - Real-time (RTS)
                              </option>
                              <option value="36">
                                Strategy - Tower Defense
                              </option>
                              <option value="38">Strategy - Other</option>
                              <option value="57">Tutorial</option>
                              <option value="77">Visual Novel</option>
                            </select>
                          </div>
                        </div>

                        <div class="flex-1"></div>

                        <div>
                          <label>API Options:</label>
                          <div class="select-wrapper">
                            <select name="api">
                              <option value="" selected="selected">
                                &ndash;
                              </option>
                              <option value="scores">Scores</option>
                              <option value="medals">Medals</option>
                              <option value="medals,scores">
                                Medals &amp; Scores
                              </option>
                              <option value="shared">Shared Content</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="flexbox padded-top form-inline"
                        id="after-or-before"
                        style="display: none"
                      >
                        <div>
                          <label>After:</label>
                          <input
                            type="text"
                            name="after"
                            value=""
                            placeholder="YYYY-MM-DD"
                            autocomplete="off"
                          />
                        </div>
                        <div class="flex-1"></div>
                        <div>
                          <label>Before:</label>
                          <input
                            type="text"
                            name="before"
                            value=""
                            placeholder="YYYY-MM-DD"
                            autocomplete="off"
                          />
                        </div>
                        <div class="flex-4"></div>
                      </div>
                    </div>

                    <div class="pod-body" id="content-results-6786592f5bfb0">
                      <div class="portalsubmission-cards">
                        <div
                          class="portalsubmission-cell"
                          data-hub-id="964331"
                          v-for="(item, index) in filteredGameList"
                          :key="index"
                          @click="navigateToGame(item.aHerf)"
                        >
                          <a
                            class="inline-card-portalsubmission"
                            :title="item.gameName"
                          >
                            <img
                              class="card-img"
                              :src="item.gameImageUrl"
                              :alt="item.gameName"
                            />
                            <div class="card-title">
                              <h4>{{ item.gameName }}</h4>
                              <span>{{ item.gameName }}</span>
                            </div>
                            <div class="rating">
                              <div
                                :class="`nohue-ngicon-small-rated-${item.ratin}`"
                              />
                            </div>

                            <div class="score-frame">
                              <div class="star-score" title="Score: 3.59/5.00">
                                <span style="width: 72%"></span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- <div
                          id="load-more-items"
                          data-playlist-func="load-more"
                        >
                          <p id="is-loading" style="display: none">
                            <em class="fa fa-spin fa-spinner"></em> Loading
                            More...
                          </p>
                          <p id="not-loading">
                            <a href="">Load More</a>
                          </p>
                        </div> -->
                      </div>
                    </div>
                  </form>
                </div>
                <div class="header-deck">
                  <div class="ng-latest-supporter-wide">
                    <amp-ad
                      width="100vw"
                      height="320"
                      type="adsense"
                      :data-ad-client="clientId"
                      :data-ad-slot="clientslotId"
                      data-auto-format="rspv"
                      data-full-width=""
                    >
                      <div overflow=""></div>
                    </amp-ad>
                    <div id="ad-container2" align="center"></div>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="body-footer-shim"></div>

      <div class="body-footer-wrapper">
        <div class="footer-features">
          <div id="footer-feature-buttons" class="footer-feature-buttons">
            <span>
              <h3>
                <span>FEATURED</span>
                <span>CONTENT</span>
              </h3>
              <div v-if="!isMobile">
                <button
                  v-for="(item, index) in meunList"
                  :key="index"
                  :class="`footer-feature-btn-${item.name.toLowerCase()}`"
                  :id="`footer-feature-btn-${item.name.toLowerCase()}`"
                  @click="handleClickBottomBtn"
                >
                  {{ item.name }}
                </button>
                <!-- <button
                  id="footer-feature-btn-games"
                  class="footer-feature-btn-games selected"
                >
                  Games
                </button>
                <button
                  id="footer-feature-btn-movies"
                  class="footer-feature-btn-movies"
                >
                  Movies
                </button>
                <button
                  id="footer-feature-btn-audio"
                  class="footer-feature-btn-audio"
                >
                  Audio
                </button>
                <button
                  id="footer-feature-btn-art"
                  class="footer-feature-btn-art"
                >
                  Art
                </button>
                <button
                  id="footer-feature-btn-channels"
                  class="footer-feature-btn-channels"
                >
                  Channels
                </button>
                <button
                  id="footer-feature-btn-users"
                  class="footer-feature-btn-users"
                >
                  Users
                </button> -->
              </div>
            </span>
          </div>
          <!-- <div class="footer-features-items" id="footer-features-items">
            <div>
              <a class="featureshift-left" id="featureshift-left"
                ><span></span
              ></a>
            </div>
            <div class="featured-items" id="footer-featured-items">
              <div class="flexbox">
                <div
                  class="featured-item"
                  v-for="item in gameBottomList"
                  :key="item.aHerf"
                >
                  <a
                    class="portalsubmission-footer-feature game"
                    href=""
                    title="Download RAM Idle 2"
                  >
                    <h4>{{ item.gameName }}</h4>
                    <div class="portalsubmision-details">
                      <div :class="`rated-${item.ratin}`">
                        <img
                          :src="item.gameImageUrl"
                          :alt="item.alt"
                          width="93"
                          height="60"
                        />
                      </div>
                      <div class="portalsubmision-details-inner">
                        <p>
                          {{ item.alt }}
                        </p>
                        <div class="score-and-views">
                          <div class="star-score" title="Score: 3.96/5.00">
                            <span style="width: 79%"></span>
                          </div>
                          <span class="views">{{ item.viewNum }} Views</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <a class="featureshift-right" id="featureshift-right"
                ><span></span
              ></a>
            </div>
          </div> -->
        </div>

        <div style="clear: both"></div>
        <div class="footer-main">
          <div class="footer-background">
            <div class="footer-wall-artist">
              <a class="flexbox inline align-center no-visited" href="">
                <div class="flex-1 text-align-right">
                  <strong class="highlight">Wall Art by</strong>
                </div>
                <div class="padded">
                  <!-- <img :src="bottomLogo" /> -->
                </div>
                <div class="flex-1">
                  <strong>{{ proName }}</strong>
                </div>
              </a>
            </div>
            <div class="footer-navigation no-visited">
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Main Sections</h4>
                  <ul>
                    <li>
                      <a href="">Games</a>
                    </li>
                    <li>
                      <a href="">Movies</a>
                    </li>
                    <li><a href="">Art</a></li>
                    <li>
                      <a href="">Audio</a>
                    </li>
                    <li>
                      <a href="" rel="nofollow">Your Feed</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Extra, Extra!</h4>
                  <ul>
                    <li>
                      <a href="">Series</a>
                    </li>
                    <li>
                      <a href="">Collections</a>
                    </li>
                    <li>
                      <a href="">Playlists</a>
                    </li>
                    <li>
                      <a href="">Game Judging</a>
                    </li>
                    <li>
                      <a href="">Movie Judging</a>
                    </li>
                    <li>
                      <a href="">Classic Portal</a>
                    </li>
                    <li>
                      <a href="" target="_blank">Snag a T-Shirt</a>
                    </li>
                    <li>
                      <a href="">Creator Resources</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>Community</h4>
                  <ul>
                    <li><a href="">Forums</a></li>
                    <li><a href="">Chat</a></li>
                    <li>
                      <a href="">Calendar</a>
                    </li>
                    <li>
                      <a href="">Artist News</a>
                    </li>
                    <li>
                      <a href="">Rankings</a>
                    </li>
                    <li>
                      <a href="">NG Wiki</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-navigation-column">
                <div class="footer-navigation-cell">
                  <h4>NG Related</h4>
                  <ul>
                    <li>
                      <a href="">About</a>
                    </li>
                    <li>
                      <a href="">Help / FAQ</a>
                    </li>
                    <li>
                      <a href="">Contact Us</a>
                    </li>
                    <li>
                      <a href="">Support NG!</a>
                    </li>
                    <li>
                      <a href="">The Staff</a>
                    </li>
                    <li>
                      <a href="">History</a>
                    </li>
                    <li>
                      <a href="">RSS</a>
                    </li>
                    <li>
                      <a href="">Copyright</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="footer-decoration"></div>
          </div>
          <div class="footer-bottom">
            <div class="footer-copyright no-visited">
              &copy;Copyright 1995-2025 Newgrounds, Inc. All rights reserved.
              <a :href="`https://${proName}.com/game/privacy-policy.html`">Privacy Policy</a>
              |
              <a :href="`https://${proName}.com/game/terms-of-service.html`">Terms of Use</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
// 菜单列表
const meunList = [
  {
    name: "Movies",
    skipUrl: "/",
    cn: "movies",
  },
  {
    name: "Games",
    skipUrl: "/",
  },
  {
    name: "Audio",
    skipUrl: "/",
  },
  {
    name: "Art",
    skipUrl: "/",
  },
  {
    name: "Portal",
    skipUrl: "/",
  },
  {
    name: "Community",
    skipUrl: "/",
  },
  // {
  //   name: "Your Feed",
  //   skipUrl: "/",
  // },
];
// 游戏列表
const gameList = ref([
  {
    gameName: "Paper Doll Fo",
    aHerf: "1",
    gameImageUrl: require("@/assets/image/gameImg/y1.png"),
    bigFlag: false,
    alt: "Paper Doll For Girls Dress Up",
    ratin: "e",
  },
  {
    gameName: "Color Link Challenge",
    aHerf: "2",
    gameImageUrl: require("@/assets/image/gameImg/y2.png"),
    bigFlag: false,
    alt: "Color Link Challenge",
    ratin: "e",
  },
  {
    gameName: "Car Jam Color",
    aHerf: "3",
    gameImageUrl: require("@/assets/image/gameImg/y3.png"),
    bigFlag: true,
    alt: "Car Jam Color",
    ratin: "e",
  },
  {
    gameName: "Happy Glass",
    aHerf: "4",
    gameImageUrl: require("@/assets/image/gameImg/y4.png"),
    bigFlag: false,
    alt: "Happy Glass",
    ratin: "e",
  },
  {
    gameName: "Slide Blocks",
    aHerf: "5",
    gameImageUrl: require("@/assets/image/gameImg/y5.png"),
    bigFlag: false,
    alt: "Slide Blocks Puzzle",
    ratin: "t",
  },
  {
    gameName: "Cannon Balls",
    aHerf: "6",
    gameImageUrl: require("@/assets/image/gameImg/y6.png"),
    bigFlag: false,
    alt: "Cannon Balls - Arcade",
    ratin: "t",
  },
  {
    gameName: "Basketball Sw",
    aHerf: "7",
    gameImageUrl: require("@/assets/image/gameImg/y7.png"),
    bigFlag: false,
    alt: "Basketball Swooshes",
    ratin: "t",
  },
  {
    gameName: "Build Tower",
    aHerf: "8",
    gameImageUrl: require("@/assets/image/gameImg/y8.png"),
    bigFlag: false,
    alt: "Build Tower",
    ratin: "t",
  },
  {
    gameName: "Mushroom Pop",
    aHerf: "9",
    gameImageUrl: require("@/assets/image/gameImg/y9.png"),
    bigFlag: false,
    alt: "Mushroom Pop",
    ratin: "t",
  },
  {
    gameName: "Jumper Bot",
    aHerf: "10",
    gameImageUrl: require("@/assets/image/gameImg/y10.png"),
    bigFlag: false,
    alt: "Jumper Bot",
    ratin: "t",
  },
  {
    gameName: "Ghost Float",
    aHerf: "11",
    gameImageUrl: require("@/assets/image/gameImg/y11.png"),
    bigFlag: false,
    alt: "Ghost Float",
    ratin: "m",
  },
  {
    gameName: "Flamingo Bing",
    aHerf: "12",
    gameImageUrl: require("@/assets/image/gameImg/y12.png"),
    bigFlag: false,
    alt: "Flamingo Bingo",
    ratin: "m",
  },
  {
    gameName: "Zombie Wipeou",
    aHerf: "13",
    gameImageUrl: require("@/assets/image/gameImg/y13.png"),
    bigFlag: false,
    alt: "Zombie Wipeout",
    ratin: "m",
  },
  {
    gameName: "Dd Pixel Slid",
    aHerf: "14",
    gameImageUrl: require("@/assets/image/gameImg/y14.png"),
    bigFlag: true,
    alt: "Dd Pixel Slide",
    ratin: "m",
  },
  {
    gameName: "Swipe The Pin",
    aHerf: "15",
    gameImageUrl: require("@/assets/image/gameImg/y15.png"),
    bigFlag: false,
    alt: "Swipe The Pin",
    ratin: "m",
  },
  {
    gameName: "Oomee Piñata",
    aHerf: "16",
    gameImageUrl: require("@/assets/image/gameImg/y16.png"),
    bigFlag: false,
    alt: "Oomee Piñata",
    ratin: "m",
  },
  {
    gameName: "Changer Jam",
    aHerf: "17",
    gameImageUrl: require("@/assets/image/gameImg/y17.png"),
    bigFlag: false,
    alt: "Changer Jam",
    ratin: "m",
  },
  {
    gameName: "Find The Set",
    aHerf: "18",
    gameImageUrl: require("@/assets/image/gameImg/y18.png"),
    bigFlag: false,
    alt: "Find The Set",
    ratin: "m",
  },
  {
    gameName: "Stickman In J",
    aHerf: "19",
    gameImageUrl: require("@/assets/image/gameImg/y19.png"),
    bigFlag: false,
    alt: "Stickman In Jail",
    ratin: "m",
  },
  {
    gameName: "Coloring Book",
    aHerf: "20",
    gameImageUrl: require("@/assets/image/gameImg/y20.png"),
    bigFlag: false,
    alt: "Coloring Book Casino",
    ratin: "a",
  },
  {
    gameName: "Fire And Wate",
    aHerf: "21",
    gameImageUrl: require("@/assets/image/gameImg/y21.png"),
    bigFlag: false,
    alt: "Fire And Water Stickman",
    ratin: "a",
  },
]);

// 底部游戏列表
const gameBottomList = ref([
  {
    gameName: "Fire And Wate",
    aHerf: "21",
    gameImageUrl: require("@/assets/image/gameImg/y21.png"),
    bigFlag: false,
    alt: "Fire And Water Stickman",
    viewNum: 5555,
    ratin: "e",
  },
  {
    gameName: "2048 Champion",
    aHerf: "22",
    gameImageUrl: require("@/assets/image/gameImg/y22.png"),
    bigFlag: true,
    alt: "2048 Champion",
    viewNum: 2137,
    ratin: "t",
  },
  {
    gameName: "Clones",
    aHerf: "23",
    gameImageUrl: require("@/assets/image/gameImg/y23.png"),
    bigFlag: false,
    alt: "Clones",
    viewNum: 1555,
    ratin: "m",
  },
  // {
  //   gameName: "Firefighter R",
  //   aHerf: "24",
  //   gameImageUrl: require("@/assets/image/gameImg/y24.png"),
  //   bigFlag: false,
  //   alt: "Firefighter Rescue Quest",
  //   viewNum: 780,
  // },
]);

// 当前选择的评分等级
const selectedRating = ref(""); // 默认为空，表示不过滤
const filteredGameList = computed(() => {
  const allChecked = ratingGrade.value.every((grade) => grade.checkedFlag);
  if (allChecked) return gameList.value;
  return gameList.value.filter(
    (game) =>
      !ratingGrade.value.find(
        (grade) => grade.value === game.ratin && !grade.checkedFlag
      )
  );
});
const navigateToGame = (index) => {
  router.push({ name: "play", query: { gameId: index } });
};
// 评分
const ratingGrade = ref([
  {
    value: "e",
    id: 1,
    checkedFlag: true,
  },
  {
    value: "t",
    id: 2,
    checkedFlag: true,
  },
  {
    value: "m",
    id: 3,
    checkedFlag: true,
  },
  {
    value: "a",
    id: 4,
    checkedFlag: true,
  },
]);
const handleClickBottomBtn = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const proName = process.env.VUE_APP_PROJECTNAME;
/**
 * adsense
 */
const clientId = process.env.VUE_APP_ADSENSEID;
const clientslotId = process.env.VUE_APP_ADSENSESLOTID;

const loadAds = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.VUE_APP_ADSENSEID}`;
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "block";

  ins.setAttribute("data-ad-client", process.env.VUE_APP_ADSENSEID);
  ins.setAttribute("data-ad-slot", process.env.VUE_APP_ADSENSESLOTID);
  const adContainer = document.getElementById("ad-container");
  if (adContainer) {
    adContainer.appendChild(script);
    adContainer.appendChild(ins);

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000);
  } else {
    console.error("Ad container not found!");
  }
};
const loadAds2 = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.VUE_APP_ADSENSEID}`;
  script.crossOrigin = "anonymous";

  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "block";
  ins.setAttribute("data-ad-client", process.env.VUE_APP_ADSENSEID);
  ins.setAttribute("data-ad-slot", process.env.VUE_APP_ADSENSESLOTID);

  const adContainer = document.getElementById("ad-container2");
  if (adContainer) {
    adContainer.appendChild(script);
    adContainer.appendChild(ins);

    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000);
  } else {
    console.error("Ad container not found!");
  }
};
const isMobile = ref(checkIsMobile());

function checkIsMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}
function handleResize() {
  isMobile.value = checkIsMobile();
}
const topLogoStyle2 = require("@/assets/image/logo-white.png");
const bottomLogo = require("@/assets/image/logo.jpg");
const logoStyle = {
  background: `url(${require("@/assets/image/logo.jpg")}) left top no-repeat`,
};

onMounted(() => {
  loadAds();
  loadAds2();
  // 初始化时先判断一次
  handleResize();

  // 监听窗口大小变化
  window.addEventListener("resize", handleResize);
});
</script>

<style lang="scss"></style>
